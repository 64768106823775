/** @format */

import React from "react";
import { Box, Typography, TextField, MenuItem } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  selectSizeChosen,
  flavorSelected,
  currentPriceUpdated,
  selectFlavourChosen,
} from "../../redux/reducers/cake.js";

import { makeStyles } from "@mui/styles";
import FlavorGuide from "./flavorGuide.jsx";

const useStyles = makeStyles((theme) => ({
  textStyle: { ...theme.textStyle },
  inputFieldText: {
    ...theme.textStyle,
    color: "#000",
  },
}));

export default function Flavor({ flavors }) {
  const classes = useStyles();
  const selectedFlavor = useSelector(selectFlavourChosen);
  const dispatch = useDispatch();

  const handleChange = (flavor) => {
    dispatch(flavorSelected([flavor]));
    dispatch(currentPriceUpdated({ flavor: [flavor] }));
  };

  return (
    <Box sx={{ mb: 2 }} data-testid="flavor">
      <Typography className={classes.textStyle} variant="h6" sx={{ mb: 1 }}>
        Flavor
      </Typography>

      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 1, width: "100%" },
        }}
        noValidate
        autoComplete="off">
        <div>
          <TextField
            id="outlined-select-size"
            select
            label="Select Flavor"
            value={selectedFlavor.length > 0 ? selectedFlavor[0].name : ""}
            InputLabelProps={{
              className: classes.inputFieldText,
            }}
            InputProps={{ className: classes.textStyle }}
            FormHelperTextProps={{ className: classes.textStyle }}>
            {flavors.map((flavor) => {
              return (
                <MenuItem
                  onClick={() => {
                    handleChange(flavor);
                  }}
                  key={flavor.name}
                  value={flavor.displayName}>
                  <span>{flavor.displayName}</span>
                  <span>
                    {flavor.price > 0
                      ? "- extra $" + flavor.price.toFixed(2)
                      : ""}
                  </span>
                  <span style={{ color: "green" }}>{` ${
                    flavor.glutenFree ? "- GLUTEN FREE" : ""
                  } `}</span>
                </MenuItem>
              );
            })}
          </TextField>
        </div>
      </Box>

      <FlavorGuide flavors={flavors} />
    </Box>
  );
}
