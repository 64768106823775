/** @format */
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Modal,
  Fade,
  Typography,
  Button,
  Paper,
  Grid,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import CustomerDetail from "./customerDetail";
// import OrderDetail from "./OrderDetail";
import OrderDetail from "./orderDetail/index";
import Status from "../Status";
import { useSelector, useDispatch } from "react-redux";
import {
  selectOrderInfo,
  selectOrders,
  orderSet,
} from "../../../../redux/reducers/admin";
import { changeOrder } from "../utils/orderEditing";
import OrderInfo from "./editOrder/orderInfo";

const useStyles = makeStyles((theme) => ({
  modalStyle: {
    position: "absolute",
    display: "block !important",
    top: "50% !important",
    left: "50% !important",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#fff !important",
    border: "none !important",
    borderRadius: "10px !important",
    height: "80% !important",
    width: "50%",
    padding: "20px 0 20px 20px !important",
    overflowY: "scroll !important",
    zIndex: "5 !important",
    [theme.breakpoints.down("xl")]: {
      width: "90%",
    },
  },
  modalTitle: {
    color: "#fd7762 !important",
    fontWeight: "700 !important",
    fontSize: "24px !important",
    textAlign: "center !important",
    marginBottom: "30px !important",
  },
  submitBtn: {
    ...theme.outlinedButton,
    margin: "8px 0 8px 0 !important",
    width: "15% !important",
  },
}));

function OrderModal({ open, handleClose }) {
  const classes = useStyles();
  const orderInfo = useSelector(selectOrderInfo);
  const [editing, setEditing] = useState(false);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Fade in={open}>
        <Paper
          className={classes.modalStyle}
          elevation={3}
          data-testid="order-modal">
          <Grid container>
            <Grid item xs={12}>
              <Typography className={classes.modalTitle}>
                ORDER DETAILS
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <CustomerDetail />
            </Grid>
            <Grid item xs={12}>
              <OrderDetail />
            </Grid>
          </Grid>

          <Box>
            <Button
              variant={"outlined"}
              className={classes.submitBtn}
              onClick={() => {
                setEditing(!editing);
              }}>
              {editing ? "close editing" : "edit order"}
            </Button>
            {editing && <OrderInfo order={orderInfo} />}
          </Box>
        </Paper>
      </Fade>
    </Modal>
  );
}

export default OrderModal;
