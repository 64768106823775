/** @format */

import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import { Grid, Box, Typography, Container, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CircularProgress from "@mui/material/CircularProgress";
import { initializeApp } from "firebase/app";
import firebaseConfig from "../../auth";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import Size from "./size";
import Flavor from "./flavor";
import {
  selectPrice,
  startingPriceSet,
  selectFlavourChosen,
  selectSizeChosen,
  selectCustomizationOptions,
  resetToInitial,
} from "../../redux/reducers/cake.js";
import { addedToTheCart, updateCart } from "../../redux/reducers/cart.js";
import CustomizationOptionsSection from "./customizationOptionsSection";
import NoteSection from "./notes/notesSection";
import FlavorGuide from "./flavorGuide.jsx";

const useStyles = makeStyles((theme) => ({
  cakePrimaryImg: {
    width: "100%",
    height: "450px",
    marginBottom: "2rem",
    [theme.breakpoints.up("sm")]: {
      height: "600px",
    },
  },
  cakeSecondaryImg: {
    height: "6rem",
    width: "6rem",
    marginBottom: "2rem",
    [theme.breakpoints.up("sm")]: {
      height: "150px",
      width: "150px",
    },
  },
  secondaryImgWrapper: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },

  addToCartBtn: {
    ...theme.containedButtonDark,
    width: "100%",
    marginBottom: "15px !important",
    marginTop: "50px !important",
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
      borderColor: theme.palette.primary.dark,
    },
  },
  textStyle: { ...theme.textStyle },
}));
export default function Body() {
  initializeApp(firebaseConfig());

  const classes = useStyles();
  const dispatch = useDispatch();
  const auth = getAuth();

  const price = useSelector(selectPrice);
  const [cake, setCake] = useState(null);
  const [primaryImg, setPrimaryImg] = useState("");
  const [sizes, setSizes] = useState([]);
  const [flavors, setFlavours] = useState([]);
  const [customizationOptions, setCustomizationOptions] = useState([]);
  const [productNotes, setNotes] = useState([]);
  const [dataIsReady, setDataIsReady] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [cakeType, setCakeType] = useState("custom-cake");
  const timer = useRef();

  const finalSize = useSelector(selectSizeChosen);
  const finalFlavor = useSelector(selectFlavourChosen);
  const finalCustomizationOptions = useSelector(selectCustomizationOptions);
  let currentUser = null;
  let sizeList = [];
  let flavorList = [];
  let customizationOptionsList = [];
  let cakeNotes = [];
  let validationErrorMessage = "";

  onAuthStateChanged(auth, (user) => {
    if (user) {
      currentUser = user;
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  });

  useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  useEffect(async () => {
    try {
      dispatch(resetToInitial());
      const cakeId = searchParams.get("cid");
      const type = searchParams.get("type");

      let res;
      if (type === "holiday") {
        setCakeType("holidayCake");
        res = await axios.get(
          `/api/app/data/holidayProducts/holidayCake?customCakeId=${cakeId}`
        );
      } else {
        res = await axios.get(
          `/api/app/data/customCake?customCakeId=${cakeId}`
        );
      }

      if (res.status != 200) {
        throw new Error(`HTTP error: ${res.status}`);
      }

      setCake(res.data);
      setPrimaryImg(res.data.images.primary);
      dispatch(startingPriceSet({ startingPrice: res.data.startingPrice }));

      const { flavorOptions, sizeOptions, customizationOptions, notes } =
        res.data;

      for (let i = 0; i < sizeOptions.length; i++) {
        res = await axios.get("/api/app/data/size?sizeId=" + sizeOptions[i]);
        if (res.status != 200) {
          throw new Error(`HTTP error: ${res.status}`);
        }
        sizeList.push(res.data);
      }

      for (let i = 0; i < flavorOptions.length; i++) {
        res = await axios.get(
          "/api/app/data/flavor?flavorId=" + flavorOptions[i]
        );
        if (res.status != 200) {
          throw new Error(`HTTP error: ${res.status}`);
        }
        flavorList.push(res.data);
      }

      for (let i = 0; i < customizationOptions.length; i++) {
        res = await axios.get(
          "/api/app/data/customizationOption?optionId=" +
            customizationOptions[i]
        );
        if (res.status != 200) {
          throw new Error(`HTTP error: ${res.status}`);
        }
        customizationOptionsList.push(res.data);
      }

      for (let i = 0; i < notes.length; i++) {
        res = await axios.get("/api/app/data/note?noteId=" + notes[i]);
        if (res.status != 200) {
          throw new Error(`HTTP error: ${res.status}`);
        }
        cakeNotes.push(res.data);
      }
      setSizes(sizeList);
      setFlavours(flavorList);
      setCustomizationOptions(customizationOptionsList);
      setNotes(cakeNotes);
      setDataIsReady(true);
    } catch (error) {
      console.log(error);
    }
  }, [dataIsReady]);

  const userSelectionAreValid = () => {
    console.log(cake);
    if (!finalSize.label) {
      validationErrorMessage = "Please select a size";
      return false;
    }

    if (finalFlavor.length === 0) {
      validationErrorMessage = "Please select a flavor";
      return false;
    }

    if (cake.customizationOptions.length > 0) {
      for (let i = 0; i < customizationOptions.length; i++) {
        let option = customizationOptions[i];

        if (
          option.name === "flakes" &&
          finalCustomizationOptions.flakeColor === null
        ) {
          validationErrorMessage = "Please choose the type of flakes";
          return false;
        }

        if (
          option.name === "colorTheme" &&
          finalCustomizationOptions.colorTheme === null
        ) {
          validationErrorMessage = "Please choose a color theme";
          return false;
        }

        if (
          option.name === "flower" &&
          finalCustomizationOptions.flower === null
        ) {
          validationErrorMessage =
            "Please choose with or without flower decorations";
          return false;
        }

        if (
          option.name === "numberCake" &&
          finalCustomizationOptions.numberCakeNumber === null
        ) {
          validationErrorMessage =
            "Please choose a two digit number for the cake";
          return false;
        }
      }
    }

    return true;
  };

  const addToCart = async () => {
    if (!userSelectionAreValid()) {
      return alert(validationErrorMessage);
    }

    setSuccess(false);
    setLoading(true);

    let applicableCustomizations = {};

    for (const optionKey in finalCustomizationOptions) {
      if (finalCustomizationOptions[optionKey] != null) {
        applicableCustomizations[optionKey] =
          finalCustomizationOptions[optionKey];
      }
    }

    console.log(applicableCustomizations);

    const product = {
      id: Date.now(),
      cakeId: cake._id,
      flavorIds: finalFlavor.map((flavor) => flavor.id),
      flavor: finalFlavor.map((flavor) => flavor.name).join(", "),
      sizeId: finalSize.id,
      customizationOptions: applicableCustomizations,
      quantity: 1,
      minQTY: 1,
      type: cakeType,
    };

    console.log(product);

    try {
      let tempEmail = localStorage.getItem("tempEmail");
      const res = await axios.post("/api/cart/addProduct/", {
        userEmail: currentUser ? currentUser.email : tempEmail,
        product,
      });

      if (res.status === 200) {
        timer.current = window.setTimeout(() => {
          dispatch(updateCart(res.data));
          // dispatch(updateCart(res.data));
          dispatch(resetToInitial());
          setSuccess(true);
          setLoading(false);
        }, 1000);
      }
    } catch (error) {
      console.log(error);
      setSuccess(false);
      setLoading(false);
    }
  };

  console.log(customizationOptions);

  return (
    <Container data-testid="product-body">
      <Grid container sx={{ mt: 10, mb: 10 }}>
        {dataIsReady && (
          <Grid item xs={12} sm={5}>
            <img className={classes.cakePrimaryImg} src={primaryImg}></img>
            <Box className={classes.secondaryImgWrapper}>
              {cake.images.secondary.length > 0 &&
                cake.images.secondary.map((imgPath) => {
                  return (
                    <img
                      className={classes.cakeSecondaryImg}
                      src={imgPath}
                      onClick={() => setPrimaryImg(imgPath)}></img>
                  );
                })}
            </Box>
          </Grid>
        )}

        <Grid item xs={12} sm={1}></Grid>

        <Grid item xs={12} sm={6}>
          {dataIsReady ? (
            <div>
              <Typography
                className={classes.textStyles}
                sx={{
                  typography: { xs: "h5", sm: "h4" },
                  mb: 4,
                  color: "#fd7762",
                }}>{`Starting at $${price.toFixed(2)} CAD`}</Typography>

              {sizes.length >= 1 && <Size sizes={sizes}></Size>}

              {flavors.length >= 1 && <Flavor flavors={flavors}></Flavor>}

              {customizationOptions.length >= 1 && (
                <CustomizationOptionsSection
                  options={customizationOptions}></CustomizationOptionsSection>
              )}

              <NoteSection notes={productNotes}></NoteSection>

              <Box sx={{ position: "relative" }}>
                <Button
                  className={classes.addToCartBtn}
                  variant="contained"
                  onClick={addToCart}>
                  <Typography className={classes.textStyle}>
                    Add to Cart
                  </Typography>
                </Button>

                {loading && (
                  <CircularProgress
                    size={24}
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "3px",
                      marginLeft: "65px",
                      color: "#fff",
                    }}
                  />
                )}
              </Box>

              {success && (
                <Typography
                  className={classes.textStyle}
                  sx={{ color: "green" }}>
                  Success! The item is added to the cart
                </Typography>
              )}
            </div>
          ) : (
            <Box>Loading...</Box>
          )}
        </Grid>
      </Grid>
    </Container>
  );
}
