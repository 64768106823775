/** @format */

import { React, useEffect, useState } from "react";
import { Grid, Typography, Box, Container, Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import { initializeApp } from "firebase/app";
import firebaseConfig from "../../auth";
import { getAuth } from "firebase/auth";
import HolidayProductCard from "./holidayProductCard";

const useStyles = makeStyles((theme) => ({
  flexStyle: {
    display: "flex !important",
    flexDirection: "column !important",
  },
  gridContainer: {
    gap: "2rem !important",
    margin: "3rem !important",
    [theme.breakpoints.down("md")]: {
      maxWidth: "100% !important",
      gap: "2rem !important",
    },
  },
  gridItem: {
    maxWidth: "30% !important",
    [theme.breakpoints.down("md")]: {
      maxWidth: "80% !important",
    },
  },
  image: {
    display: "flex !important",
    justifyContent: "center !important",
    alignItems: "center !important",
    borderRadius: "10% !important",
    minWidth: "310px",
    minHeight: "400px",
    backgroundColor: "gray !important",
    [theme.breakpoints.up("sm")]: {
      width: "4rem !important",
      height: "4rem !important",
      marginTop: "1rem !important",
    },
  },
}));

export default function Products({ type }) {
  initializeApp(firebaseConfig());

  const classes = useStyles();

  const [dataIsReady, setDataIsReady] = useState(false);
  const [holidayProducts, setHolidayProducts] = useState([]);

  useEffect(async () => {
    try {
      const res = await axios.get("/api/app/data/holidayProducts");

      setHolidayProducts(res.data.products);
      setDataIsReady(true);
    } catch (error) {
      console.log(error);
    }
  }, [type]);

  return (
    <Container
      sx={{
        display: "flex !important",
        flexDirection: "column !important",
        justifyContent: "center !important",
        alignItems: "center !important",
      }}>
      {dataIsReady ? (
        <Grid container className={classes.gridContainer} spacing={3}>
          {holidayProducts.map((product, index) => {
            if (product.holidayType === type && product.isActive) {
              return (
                <Grid item xs={4} key={index} className={classes.gridItem}>
                  <Box className={classes.flexStyle}>
                    <img
                      src={product.images.primary}
                      className={classes.image}
                    />
                    <HolidayProductCard holidayProduct={product} />
                  </Box>
                </Grid>
              );
            }
          })}
        </Grid>
      ) : (
        <Box>Loading...</Box>
      )}
    </Container>
  );
}
