import React, { useState } from "react";
import {
  Button,
  Box,
  Container,
  Grid,
  TextField,
  Typography,
  Select,
  MenuItem,
  Divider,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  nextButton: {
    "&.MuiButton-root": {
      color: "#f6725c",
      fontSize: "18px",
    },
    "&.Mui-disabled": {
      color: "lightgrey",
    },
  },
  backButton: {
    "&.MuiButton-root": {
      color: "#f6725c",
      fontSize: "18px",
    },
  },
  arrowsBox: {
    display: "flex !important",
    justifyContent: "space-between !important",
    alignItems: "center !important",
    position: "sticky !important",
    bottom: 0,
    backgroundColor: "white !important",
    padding: theme.spacing(4),
    "&:hover": {
      backgroundColor: "white !important",
    },
    zIndex: 10,
  },
  container: {
    minHeight: "70vh",
    padding: "0 2rem 0 6rem !important",
    [theme.breakpoints.between("sm", "md")]: {
      padding: "0 0 0 2rem !important",
      minHeight: "60vh",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0 0 0 2rem !important",
    },
  },
}));

function OrderInfo({ order }) {
  const classes = useStyles();
  const [newOrder, setNewOrder] = useState(order);
  const [customerInfo, setCustomerInfo] = useState(order.customerInfo);
  const [collectionDate, setCollectionDate] = useState(order.collectionDate);
  const [collectionTime, setCollectionTime] = useState(order.collectionTime);
  const [collectionLocation, setCollectionLocation] = useState(
    order.collectionLocation
  );
  const [products, setProducts] = useState(newOrder.products);
  const [adminKey, setAdminKey] = useState(null);

  const [onFieldFocus, setOnFieldFocus] = useState({
    name: false,
    email: false,
    phone: false,
    address: false,
  });

  const notEditableOrderProductFields = [
    "id",
    "customizations",
    "type",
    "name",
  ];

  const availableTimeSlots = [
    "11:00 AM",
    "12:00 PM",
    "1:00 PM",
    "2:00 PM",
    "3:00 PM",
    "4:00 PM",
    "5:00 PM",
    "6:00 PM",
  ];

  const handleCustomerInfoChange = (e, key) => {
    setNewOrder({
      ...newOrder,
      customerInfo: { ...newOrder.customerInfo, [key]: e.target.value },
    });
  };

  const handleProductInfoChange = (e, key, productIndex) => {
    setNewOrder({
      ...newOrder,
      products: [
        {
          ...newOrder.products[productIndex],
          [key]:
            key === "price" || key === "quantity"
              ? e.target.value === ""
                ? 0
                : parseInt(e.target.value)
              : e.target.value,
        },
        ...newOrder.products.slice(1),
      ],
    });
  };

  const handlePhoneChange = (e) => {
    let rawNumber = e.target.value.replace(/[^\d]/g, "");
    if (rawNumber.length > 10) {
      rawNumber = rawNumber.toString().slice(0, 10);
    } else if (rawNumber.length === 10) {
      const formattedNumber = `(${rawNumber.slice(0, 3)}) ${rawNumber.slice(
        3,
        6
      )}-${rawNumber.slice(6)}`;
      if ("phoneNumber" in newOrder.customerInfo) {
        setNewOrder({
          ...newOrder,
          customerInfo: {
            ...newOrder.customerInfo,
            phoneNumber: formattedNumber,
          },
        });
      } else {
        setNewOrder({
          ...newOrder,
          customerInfo: { ...newOrder.customerInfo, phone: formattedNumber },
        });
      }
    } else {
      if ("phoneNumber" in newOrder.customerInfo) {
        setNewOrder({
          ...newOrder,
          customerInfo: {
            ...newOrder.customerInfo,
            phoneNumber: e.target.value,
          },
        });
      } else {
        setNewOrder({
          ...newOrder,
          customerInfo: { ...newOrder.customerInfo, phone: e.target.value },
        });
      }
    }
  };

  const handleCollectionDateChange = (newValue) => {
    const newDate = dayjs(newValue).format("YYYY-MM-DD HH:MM");
    setNewOrder({ ...newOrder, collectionDate: newDate });
  };

  const handleCollectionTimeChange = (newValue) => {
    setNewOrder({ ...newOrder, collectionTime: newValue });
  };

  const handleCollectionLocationChange = (newValue) => {
    setNewOrder({ ...newOrder, collectionLocation: newValue });
  };

  const handleAdminKeyChange = (newValue) => {
    setAdminKey(newValue);
  };

  const validateField = (fieldKey, fieldValue) => {
    if (fieldKey === "phone") {
      return /^\(\d{3}\)\d{3}-\d{4}$/.test(fieldValue)
        ? ""
        : "Please enter a valid phone number (e.g. (709)111-1111)";
    } else if (fieldKey === "email") {
      return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(fieldValue)
        ? ""
        : "Please enter a valid email address";
    } else {
      return "";
    }
  };

  const now = dayjs();
  const currentTime = now.format("HH:mm");
  const minDate = currentTime > "14:00" ? now.add(2, "day") : now.add(1, "day");

  const handleTotalChange = (newValue) => {
    setNewOrder({
      ...newOrder,
      total: newValue === "" ? 0 : parseInt(newValue),
    });
  };

  const handleAdditionalNotesChange = (newValue) => {
    setNewOrder({ ...newOrder, additionalNotes: newValue });
  };

  const saveEdits = async () => {
    try {
      if (adminKey) {
        const headers = { Authorization: adminKey };
        const res = await axios.put("/api/admin/order/editOrder", newOrder, {
          headers,
        });
        if (res.status === 200) {
          alert("order edited successfully");
        } else {
          alert("there was an error on the server side. Check the logs");
        }
      } else {
        alert("please provide an admin key to be able to make this change");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Container className={classes.container} data-testid="customer-info">
      <Typography variant="h6" sx={{ mt: 8, marginBottom: "20px" }}>
        Customer Information
      </Typography>
      <Grid container spacing={3}>
        {Object.keys(newOrder.customerInfo).map((key) => (
          <Grid item xs={12} sm={6} key={key}>
            <TextField
              fullWidth
              disabled={key === "email"}
              required={key !== "address"}
              label={key.charAt(0).toUpperCase() + key.slice(1)}
              value={newOrder.customerInfo[key]}
              onChange={(e) => {
                if (key === "phone" || key === "phoneNumber") {
                  handlePhoneChange(e);
                } else {
                  handleCustomerInfoChange(e, key);
                  //   setCustomerInfo({ ...customerInfo, [key]: e.target.value });
                }
              }}
              //   onBlur={() => setOnFieldFocus({ ...onFieldFocus, [key]: true })}
              sx={{
                width: "90%",
                height: "5rem",
                "& .MuiInputBase-root": { backgroundColor: "white" },
              }}
              error={
                onFieldFocus[key] &&
                validateField(key, newOrder.customerInfo[key]) !== ""
              }
              helperText={
                onFieldFocus[key] &&
                validateField(key, newOrder.customerInfo[key])
              }
            />
          </Grid>
        ))}
      </Grid>
      <Typography variant="h6" sx={{ mt: 8, mb: 4 }}>
        Event Information
      </Typography>
      <Grid container>
        <Grid item xs={12} sm={4}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
            }}>
            <Typography
              variant="h6"
              sx={{ mr: 2, fontSize: "18px", color: "#5d5d5d" }}>
              Pick Up Date
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                value={dayjs(collectionDate)}
                minDate={minDate}
                maxDate={dayjs().add(6, "months")}
                onChange={(date) => {
                  handleCollectionDateChange(date);
                }}
                sx={{
                  width: "60% !important",
                  "& .MuiInputBase-root": { backgroundColor: "white" },
                }}
              />
            </LocalizationProvider>
          </Box>
        </Grid>
        <Grid sm={4}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <Typography
              variant="h6"
              sx={{ mr: 2, fontSize: "18px", color: "#5d5d5d" }}>
              Pick Up Time
              <br />
              <span style={{ fontSize: "16px" }}>(11am-6pm)</span>
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Select
                value={newOrder.collectionTime}
                onChange={(e) => {
                  handleCollectionTimeChange(e.target.value);
                }}
                sx={{
                  width: "55% !important",
                  "& .MuiSelect-select": { backgroundColor: "white" },
                }}>
                {availableTimeSlots.map((slot, index) => (
                  <MenuItem
                    key={index}
                    value={slot}
                    sx={{
                      justifyContent: "center",
                      fontSize: "1.1rem",
                      boxShadow: "0 1px 4px rgba(0, 0, 0, 0.2)",
                      margin: "0.5rem",
                      "&:hover": {
                        backgroundColor: "lightgrey",
                      },
                    }}>
                    {slot}
                  </MenuItem>
                ))}
              </Select>
            </LocalizationProvider>
          </Box>
        </Grid>
        <Grid sm={4}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <Typography
              variant="h6"
              sx={{ mr: 2, fontSize: "18px", color: "#5d5d5d" }}>
              Pick Up Location
              <br />
            </Typography>
            <Select
              value={newOrder.collectionLocation}
              onChange={(e) => {
                handleCollectionLocationChange(e.target.value);
              }}
              sx={{
                width: "55% !important",
                "& .MuiSelect-select": { backgroundColor: "white" },
              }}>
              <MenuItem
                key={"1"}
                value={"1483 Topsail Rd."}
                sx={{
                  justifyContent: "center",
                  fontSize: "1.1rem",
                  boxShadow: "0 1px 4px rgba(0, 0, 0, 0.2)",
                  margin: "0.5rem",
                  "&:hover": {
                    backgroundColor: "lightgrey",
                  },
                }}>
                {"1483 Topsail Rd."}
              </MenuItem>
              <MenuItem
                key={"2"}
                value={"16 Stavanger Dr."}
                sx={{
                  justifyContent: "center",
                  fontSize: "1.1rem",
                  boxShadow: "0 1px 4px rgba(0, 0, 0, 0.2)",
                  margin: "0.5rem",
                  "&:hover": {
                    backgroundColor: "lightgrey",
                  },
                }}>
                {"16 Stavanger Dr."}
              </MenuItem>
            </Select>
          </Box>
        </Grid>
      </Grid>
      <Typography variant="h6" sx={{ mt: 8, mb: 4 }}>
        Order Products Information
      </Typography>
      <Grid container spacing={3}>
        {newOrder.products.map((product, index) => {
          return (
            <Grid item>
              <Typography variant="h5" sx={{ mb: 2 }}>
                Product {index + 1}:
              </Typography>
              <Grid container>
                {Object.keys(product).map((key) => {
                  if (!notEditableOrderProductFields.includes(key)) {
                    return (
                      <Grid item xs={12} sm={6} key={key}>
                        <Typography>{key}</Typography>
                        <TextField
                          fullWidth
                          disabled={key === "email"}
                          required={key !== "address"}
                          value={product[key]}
                          onChange={(e) => {
                            handleProductInfoChange(e, key, index);
                          }}
                          sx={{
                            width: "90%",
                            height: "5rem",
                            "& .MuiInputBase-root": {
                              backgroundColor: "white",
                            },
                          }}
                        />
                      </Grid>
                    );
                  }
                })}
              </Grid>
              <Divider sx={{ mb: 3 }} />
            </Grid>
          );
        })}
      </Grid>
      <Typography variant="h6" sx={{ mt: 8, mb: 4 }}>
        Other Fields
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Total"
            value={newOrder.total}
            onChange={(e) => handleTotalChange(e.target.value)}
            sx={{
              width: "90%",
              height: "5rem",
              "& .MuiInputBase-root": { backgroundColor: "white" },
            }}
          />
          <TextField
            fullWidth
            label="Additional Notes"
            value={newOrder.additionalNotes}
            onChange={(e) => handleAdditionalNotesChange(e.target.value)}
            sx={{
              width: "90%",
              height: "5rem",
              "& .MuiInputBase-root": { backgroundColor: "white" },
            }}
          />
        </Grid>
      </Grid>
      <Divider />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            label="ADMIN KEY"
            value={adminKey}
            onChange={(e) => handleAdminKeyChange(e.target.value)}
            sx={{
              width: "50%",
              height: "5rem",
              mr: 3,
              mt: 5,
              "& .MuiInputBase-root": { backgroundColor: "white" },
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Button onClick={() => saveEdits()} variant="contained">
            Save Changes
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
}

export default OrderInfo;
