/** @format */

import React from "react";
import { Box, Typography, TextField, MenuItem } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  selectSizeChosen,
  sizeSelected,
  currentPriceUpdated,
} from "../../redux/reducers/cake.js";

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  textStyle: { ...theme.textStyle },
  inputFieldText: {
    ...theme.textStyle,
    color: "#000",
  },
}));

export default function Size({ sizes }) {
  const classes = useStyles();
  const size = useSelector(selectSizeChosen);
  const dispatch = useDispatch();

  const handleChange = (size) => {
    dispatch(sizeSelected(size));
    dispatch(currentPriceUpdated());
  };

  return (
    <Box sx={{ mb: 2 }} data-testid="size">
      <Typography className={classes.textStyle} variant="h6" sx={{ mb: 1 }}>
        Size
      </Typography>

      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 1, width: "100%" },
        }}
        noValidate
        autoComplete="off">
        <div>
          <TextField
            id="outlined-select-size"
            select
            label="Select Size"
            value={size.label}
            InputLabelProps={{
              className: classes.inputFieldText,
            }}
            InputProps={{ className: classes.textStyle }}
            FormHelperTextProps={{ className: classes.textStyle }}>
            {sizes.map((size) => {
              return (
                <MenuItem
                  onClick={() => {
                    handleChange(size);
                  }}
                  key={size.label}
                  value={size.label}>
                  {`${size.label} - serves up to ${size.feedSize} people ${
                    size.price > 0 ? ` - extra $${size.price.toFixed(2)}` : ""
                  }`}
                </MenuItem>
              );
            })}
          </TextField>
        </div>
      </Box>
    </Box>
  );
}
