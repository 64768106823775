import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  Container,
  Grid,
  Typography,
  Button,
  MenuItem,
  Select,
  Paper,
  Box,
} from "@mui/material";
import PropTypes from "prop-types";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { makeStyles } from "@mui/styles";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import axios from "axios";
import {
  collectionDateChosen,
  collectionTimeChosen,
} from "../../../redux/reducers/cart";

const useStyles = makeStyles((theme) => ({
  yearMonthSelection: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      display: "block",
    },
  },
  nextprevButtons: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      justifyContent: "start",
    },
  },
  prevButton: {
    padding: "8px 15px 11px 11px",
    border: "1px solid grey",
    width: "30px",
    height: "30px",
  },
  nextButton: {
    padding: "8px 15px 11px 11px",
    border: "1px solid grey",
    width: "30px",
    height: "30px",
    [theme.breakpoints.up("md")]: {
      marginLeft: "20px",
    },
  },
  selectedDayCardWrapper: {
    padding: "10px",
    borderRadius: "10px",
    backgroundColor: "#ffddd8",
    marginRight: "8px",
    marginBottom: "16px",
    transition: "background-color 0.5s ease",
  },
  unselectedDayCardWrapper: {
    padding: "10px",
    borderRadius: "10px",
    backgroundColor: "#ffffff",
    marginRight: "8px",
    marginBottom: "16px",
  },
  notSelectedDayCard: {
    padding: "10px",
    borderRadius: "10px",
    backgroundColor: "#ffffff",
    marginRight: "8px",
    marginBottom: "16px",
  },
  dateCardUnselected: {
    padding: "30px 20px",
    textAlign: "center",
    outline: "1px solid grey",
    "&:hover": {
      cursor: "pointer",
    },
  },
  dateCardSelected: {
    padding: "30px 20px",
    textAlign: "center",
    outline: "1px solid #fd7762",
    "&:hover": {
      cursor: "pointer",
    },
  },
  fullyBookedDateCardDisabled: {
    padding: "30px 20px",
    textAlign: "center",
    outline: "1px solid #828282",
    backgroundColor: "#f2f2f2",
  },
  dateSelectionWrapper: {
    [theme.breakpoints.up("md")]: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
  },
  selectedTimeSlot: {
    backgroundColor: "#ffddd8",
    padding: "15px 15px",
    margin: "0px 20px 20px 0",
    textAlign: "center",
    transition: "background-color 0.5s ease",
    "&:hover": {
      cursor: "pointer",
    },
  },
  unselectedTimeSlot: {
    backgroundColor: "#ffffff",
    padding: "15px 15px",
    margin: "0px 20px 20px 0",
    textAlign: "center",
    "&:hover": {
      cursor: "pointer",
    },
  },
  bookedTimeSlot: {
    backgroundColor: "#f2f2f2",
    padding: "15px 15px",
    margin: "0px 20px 20px 0",
    textAlign: "center",
  },
}));

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const monthNumbers = {
  January: 0,
  February: 1,
  March: 2,
  April: 3,
  May: 4,
  June: 5,
  July: 6,
  August: 7,
  September: 8,
  October: 9,
  November: 10,
  December: 11,
};

const weekDays = ["Sun", "Mon", "Tue", "Wed", "Thur", "Fri", "Sat"];

export default function DateSelectionDialog(props) {
  const { onClose, open } = props;

  const classes = useStyles();
  const dispatch = useDispatch();

  const currentYear = new Date().getFullYear();
  const currentMonthIndex = new Date().getMonth();
  const currentMonth = monthNames[currentMonthIndex];
  const currentDay = new Date().getDate();
  const currentHour = new Date().getHours();

  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);
  const [selectedDay, setSelectedDay] = useState(null);
  const [selectedTimeslot, setSelectedTimeslot] = useState(null);

  const [generalAvailability, setGeneralAvailability] = useState(null);
  const [futureDaysInTheSelectedMonth, setFutureDaysInTheSelectedMonth] =
    useState([]);
  const [dataIsReady, setDataIsReady] = useState(false);
  const [daysToBeRendered, setDaysToBeRendered] = useState([]);
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(8);

  let firstSixDays = [];

  const setDatesToBeRendered = (start, end, direction) => {
    if (direction === "next") {
      // the if statement prevents from going past the end of the available future dates (next button)
      // vice versa the else statement prevents from going past the first available future date (prev button)
      // (which is typically the next day)
      if (end + 1 <= futureDaysInTheSelectedMonth.length) {
        start = start + 1;
        end = end + 1;

        setStart(start);
        setEnd(end);
      }
    } else {
      if (start - 1 >= 0) {
        start = start - 1;
        end = end - 1;

        setStart(start);
        setEnd(end);
      }
    }

    let updatedSixDays = [];
    for (let i = start; i < end; i++) {
      updatedSixDays.push(futureDaysInTheSelectedMonth[i]);
    }
    setDaysToBeRendered(updatedSixDays);
  };

  const fetchData = async () => {
    try {
      const res = await axios.get("/api/app/data/availability");
      if (res.status === 200) {
        setGeneralAvailability(res.data);
        setDataIsReady(true);
      } else {
        console.log("error getting availability");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (dataIsReady) {
      const availabilityBySelectedMonth = generalAvailability.find(
        (a) => a.month === selectedMonth
      );

      let futureDays;
      if (availabilityBySelectedMonth) {
        if (
          monthNames.indexOf(currentMonth) === monthNames.indexOf(selectedMonth)
        ) {
          if (currentHour >= 14) {
            // do not render the next day if it's past 2pm
            futureDays = availabilityBySelectedMonth.days.filter(
              (d) => d.day > currentDay + 1
            );
          } else {
            futureDays = availabilityBySelectedMonth.days.filter(
              (d) => d.day > currentDay
            );
          }
        } else {
          // this is the case when the selected month is greater than the current month
          // the smaller than case is not considered because the user won't be able to select a month that is in the past
          // for example, if the current month is March, the user won't have the option to select Februrary from the dropdown
          futureDays = availabilityBySelectedMonth.days;
        }

        setFutureDaysInTheSelectedMonth(futureDays);
      }

      for (let i = 0; i < 8; i++) {
        if (futureDays[i] !== undefined) {
          firstSixDays.push(futureDays[i]);
        }
      }

      setDaysToBeRendered(firstSixDays);
    }
  }, [dataIsReady, selectedMonth]);

  const handleClose = () => {
    onClose(true);
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const handleMonthChange = (event) => {
    let newMonth = event.target.value;
    setSelectedDay(null);
    setSelectedMonth(newMonth);
    setStart(0);
    setEnd(8);
  };

  const handleDateChange = (userChosenDate) => {
    if (userChosenDate.timeslots.find((t) => t.booked === false)) {
      dispatch(
        collectionDateChosen(
          `${userChosenDate.day}-${selectedMonth}-${selectedYear}`
        )
      );
      setSelectedDay(userChosenDate.day);
    }
  };

  const handleTimeslotChange = (userChosenTimeslot) => {
    dispatch(collectionTimeChosen(userChosenTimeslot.hour));
    setSelectedTimeslot(userChosenTimeslot.hour);
    onClose(true);
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <Container spacing={2}>
        <Grid container>
          <Grid item xs={12} sx={{ mt: 3, mb: 3 }}>
            <DialogTitle sx={{ padding: 0, fontWeight: "bold" }}>
              Choose a date
            </DialogTitle>
          </Grid>
        </Grid>

        <Box className={classes.dateSelectionWrapper}>
          <Box className={classes.yearMonthSelection}>
            <Select
              value={selectedYear}
              onChange={handleYearChange}
              sx={{ mr: "20px", minWidth: "100px" }}>
              <MenuItem value={2024}>2024</MenuItem>
              <MenuItem value={2025}>2025</MenuItem>
            </Select>
            <Select
              value={selectedMonth}
              onChange={handleMonthChange}
              sx={{ minWidth: "100px" }}>
              {monthNames.map((month, i) => {
                if (monthNames.indexOf(month) >= currentMonthIndex) {
                  return (
                    <MenuItem value={month} key={i}>
                      {month}
                    </MenuItem>
                  );
                }
                if (
                  monthNames.indexOf(month) < currentMonthIndex &&
                  selectedYear > currentYear
                ) {
                  return (
                    <MenuItem value={month} key={i}>
                      {month}
                    </MenuItem>
                  );
                }
              })}
            </Select>
          </Box>

          <Box className={classes.nextprevButtons}>
            <Paper
              className={classes.prevButton}
              onClick={() => setDatesToBeRendered(start, end, "prev")}>
              <ChevronLeftIcon fontSize="large" style={{ color: "#fd7762" }} />
            </Paper>

            <Paper
              className={classes.nextButton}
              onClick={() => setDatesToBeRendered(start, end, "next")}>
              <ChevronRightIcon fontSize="large" style={{ color: "#fd7762" }} />
            </Paper>
          </Box>
        </Box>
      </Container>

      {/* if there are future days in the selected month (that is it is not the last day of the month)
      then daysToBeRendered will not be an empty array and the UI will render these days*/}
      {dataIsReady && daysToBeRendered.length > 0 ? (
        <Container>
          <Grid container>
            {daysToBeRendered.map((date, index) => (
              <Grid item xs={6} md={3} key={index}>
                <Box
                  className={
                    selectedDay === date.day
                      ? classes.selectedDayCardWrapper
                      : classes.unselectedDayCardWrapper
                  }>
                  <Paper
                    elevation={5}
                    className={
                      selectedDay === date.day
                        ? classes.dateCardSelected
                        : date.timeslots.find((t) => t.booked === false)
                        ? classes.dateCardUnselected
                        : classes.fullyBookedDateCardDisabled
                    }
                    onClick={() => handleDateChange(date)}>
                    <Typography
                      variant="subtitle2"
                      sx={{
                        color:
                          selectedDay === date.day
                            ? "#fd7762"
                            : date.timeslots.find((t) => t.booked === false)
                            ? "#000000"
                            : "#828282",
                        fontSize: "18px",
                        fontWeight: "bold",
                        mb: 1,
                      }}>
                      {
                        weekDays[
                          new Date(
                            selectedYear,
                            monthNumbers[selectedMonth],
                            date.day
                          ).getDay()
                        ]
                      }
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{
                        color: date.timeslots.find((t) => t.booked === false)
                          ? "#fd7762"
                          : "#828282",
                        fontSize: "30px",
                        fontWeight: "bold",
                        mb: 1,
                      }}>
                      {date?.displayDay}
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        alignContent: "center",
                        alignItems: "center",
                        justifyContent: "center",
                      }}>
                      {date.timeslots.filter((t) => t.booked === false).length >
                        0 && (
                        <FiberManualRecordIcon
                          style={{
                            transform: "scale(0.7)",
                            color:
                              date.timeslots.filter((t) => t.booked === false)
                                .length > 5
                                ? "green"
                                : date.timeslots.filter(
                                    (t) => t.booked === false
                                  ).length >= 3 &&
                                  date.timeslots.filter(
                                    (t) => t.booked === false
                                  ).length <= 5
                                ? "orange"
                                : "red",
                          }}
                        />
                      )}

                      {date.timeslots.filter((t) => t.booked === false).length >
                      0 ? (
                        <Typography
                          variant="caption"
                          sx={{
                            color:
                              selectedDay === date.day ? "#fd7762" : "#000000",
                            fontSize: "14px",
                          }}>
                          {`${
                            date.timeslots.filter((t) => t.booked === false)
                              .length
                          } slots`}
                        </Typography>
                      ) : (
                        <HorizontalRuleIcon
                          style={{
                            color: date.timeslots.find(
                              (t) => t.booked === false
                            )
                              ? "#000000"
                              : "#828282",
                          }}
                        />
                      )}
                    </Box>
                  </Paper>
                </Box>
              </Grid>
            ))}
          </Grid>

          <Grid container>
            <Grid item xs={12} sx={{ mt: 3, mb: 3 }}>
              <DialogTitle sx={{ padding: 0, fontWeight: "bold" }}>
                Choose a time slot
              </DialogTitle>
            </Grid>

            {!selectedDay && (
              <Box sx={{ margin: "0px 0px 20px 0px" }}>
                <Typography>
                  Select a date to see the available time slots
                </Typography>
              </Box>
            )}

            {selectedDay &&
              daysToBeRendered
                .find((d) => d.day === selectedDay)
                .timeslots.map((timeslot, index) => (
                  <Grid item xs={6} md={3} key={index}>
                    {timeslot.booked ? (
                      <Paper elevation={3} className={classes.bookedTimeSlot}>
                        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                          {timeslot.hour}
                        </Typography>
                      </Paper>
                    ) : (
                      <Paper
                        elevation={3}
                        className={
                          selectedTimeslot === timeslot.hour
                            ? classes.selectedTimeSlot
                            : classes.unselectedTimeSlot
                        }
                        onClick={() => handleTimeslotChange(timeslot)}>
                        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                          {timeslot.hour}
                        </Typography>
                      </Paper>
                    )}
                  </Grid>
                ))}
          </Grid>
        </Container>
      ) : (
        // in this scenario we are at the last day of the month and since we do not allow ordering for the same day
        // we simply show 'no dates available to select' and ask the user to select another month from the dropdown list
        <Box sx={{ margin: "20px 0px" }}>
          {dataIsReady && daysToBeRendered.length === 0 ? (
            <Box>
              no dates to choose in the selected month. Please choose another
              month
            </Box>
          ) : (
            <Box>Loading...</Box>
          )}
        </Box>
      )}
    </Dialog>
  );
}

DateSelectionDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};
